import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { Form, Input, Select, DatePicker, InputNumber } from "antd";
import { Row, Col, List, Tabs } from "antd";
import { Button } from "antd";
import PromptInput from "./Input/PromptInput";
import JsonInput from "./Input/JsonInput";
import { useEffect } from "react";
import { useState } from "react";
import { Slider } from "antd";
import { queryItem, executeFormOperation } from "../../Api/manager";
import { useLocation } from "react-router-dom";
import ImageUpload from "./Input/ImageUpload";
import moment from "moment";
const CommonForm = ({ searchDefine, formOperationItems, viewName, queryParams: initialQueryParams, ...props }) => {


    const [dataItem, setDataItem] = useState({})
    const [form] = useForm();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState(initialQueryParams);
    const navigation = props?.navigation



    const updateNavigation = (item) => {
        if (navigation?.query_params) {
            var navigationQueryParams = {}
                for (const key in navigation.query_params) {
                    navigationQueryParams[key] = item[navigation.query_params[key]]
                }
            console.log(navigationQueryParams)
        }
    }


    const queryDataItem = () => {
        queryItem(viewName, queryParams).then(res => {
            const data = res.data.data
            for (const key in data) {

                const item = searchDefine.find(p => p.column === key)
                if (item) {
                    if (item.input_type === "datetime") {
                        console.log(data[key],key)
                        const momentDate = moment(data[key])
                        if (momentDate.isValid()) {
                            data[key] = momentDate 
                        } else {
                            data[key] = moment(data[key]) //.format("YYYY-MM-DD HH:mm:ss")
                        }
                    }
                }


            }
            console.log(data)
            setDataItem(data)
            form.setFieldsValue(data)
            updateNavigation(data)
        })
    }
    const {state} = useLocation()
    useEffect(() => {
        if (queryParams !== undefined && Object.keys(queryParams).length > 0) {
            queryDataItem()
        }else{
            console.log(state)
            // setQueryParams(state)
            setDataItem({...state,...dataItem})
        }
    }, [queryParams])



    const listItemClick = (item) => {
        setQueryParams({ ...queryParams, id: item });
        navigate(`/${viewName}?id=${item}`)
        
    } 

    const randerTab = (tabs) => {
        return <Tabs
            items={Object.keys(tabs).map(key => {
                return {
                    label: key,
                    key: key,
                    children: tabs[key]
                }
            })}>
        </Tabs>

    }
    const getInputEnable = (item) => {
        if (form) {
            for (const key in item.enable_filter) {
                if (String(dataItem[key]).trim() !== String(item.enable_filter[key]).trim()) {
                    return false
                }
            }
            return true
        }
        return true
    }
    const getInputVisible = (item) => {
        return item.input_type === "label" || item.input_type === "text"
    }
    const handleFormChange = (changedValues, allValues) => {
        if (changedValues.length === 0) {
            return
        }
        setDataItem(allValues)
    }
    useEffect(() => {
        form.setFieldsValue(dataItem);
    }, [dataItem, form]);
    const handleFormOperation = (operation, items) => {
        console.log(dataItem)
        const submitData = {...dataItem}
        for (const key in dataItem) {
            console.log(typeof dataItem[key])
            if (dataItem[key] && searchDefine.find(p => p.column === key)?.input_type === "datetime") {
                submitData[key] = dataItem[key].format('YYYY-MM-DD HH:mm:ss')
            }
        }
        console.log(submitData)
        executeFormOperation(viewName, operation.label, submitData,state).then(res => {
            navigate(-1)
        })
    }

    const renderForm = () => {
        var tabs = {};
        return (
            <Row>
                {/* {navigation && <Col span={4}>
                    <List size="small" onClick={(e) => listItemClick(e.target.value)}>
                        <List.Item value="1" key="1">列表项 1</List.Item>
                        <List.Item value="2" key="2">列表项 2</List.Item>
                        <List.Item value="3" key="3">列表项 3</List.Item>
                    </List>
                </Col>} */}
                <Col span={18}>
                    <Form
                        layout="horizontal"
                        onValuesChange={handleFormChange}
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 10 }}
                        form={form}
                        initialValues={dataItem}
                    >
                        {

                            searchDefine.map((item, index) => {
                                switch (item.input_type) {
                                    case "label":
                                        return (
                                            <Form.Item
                                                style={{ width: "100" }}
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <Input
                                                    style={{ width: "100" }}
                                                    disabled={true}
                                                    value={dataItem[item.column]}
                                                />
                                            </Form.Item>
                                        );
                                    case "text":
                                        return (
                                            <Form.Item
                                                style={{ width: "auto" }}
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <Input disabled={!getInputEnable(item)} />
                                            </Form.Item>
                                        );
                                    case "select":
                                        return (
                                            <Form.Item
                                                style={{ width: "100%" }}
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <Select
                                                    onChange={(value) => {
                                                        setDataItem({ ...dataItem, [item.column]: value });
                                                    }}
                                                    disabled={!getInputEnable(item)}
                                                    value={dataItem[item.column]}
                                                    options={item.input_options}
                                                />
                                            </Form.Item>
                                        );
                                    case "date":
                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <DatePicker disabled={!getInputEnable(item)} />
                                            </Form.Item>
                                        );
                                    case "slider":
                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <Slider
                                                    tooltip={{ open: true }}
                                                    disabled={!getInputEnable(item)}
                                                    min={item.min}
                                                    max={item.max}
                                                    step={item.step}
                                                />
                                            </Form.Item>
                                        );
                                    case "number":
                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <InputNumber 
                                                    disabled={!getInputEnable(item)} 
                                                    {...item.min !== undefined && { min: item.min }}
                                                    {...item.max !== undefined && { max: item.max }}
                                                    {...item.step !== undefined && { step: item.step }}
                                                    {...item.precision !== undefined && { precision: item.precision }}
                                                />
                                            </Form.Item>
                                        );
                                    case "prompt":
                                        tabs[item.label] = (
                                            <Form.Item name={item.column} key={index}>
                                                <PromptInput
                                                    enable={getInputEnable(item)}
                                                    value={dataItem[item.column]}
                                                    model_ref={dataItem[item.model_ref]}
                                                    temprature_ref={dataItem[item.temprature_ref]}
                                                    tips={item.tips}
                                                    models={item.model_ref ? searchDefine.filter(p => p.column === item.model_ref)[0]?.input_options : []}
                                                    onChange={(value, model, temprature) => {
                                                        console.log('onChange', { value, model, temprature })
                                                        setDataItem({ ...dataItem, [item.column]: value, [item.model_ref]: model, [item.temprature_ref]: temprature });
                                                    }}
                                                    {...(item.pass_params && typeof item.pass_params === 'object' ?
                                                        Object.entries(item.pass_params).reduce((acc, [key, value]) => {
                                                            acc[key] = dataItem?.[value];
                                                            return acc;
                                                        }, {}) : {})}
                                                />
                                            </Form.Item>
                                        );
                                        break;
                                    case "image":
                                        return (
                                            <Form.Item label={item.label} name={item.column} key={index} value={dataItem[item.column]} onChange={(value)=>{
                                                setDataItem({...dataItem,[item.column]:value})
                                            }}>
                                                <ImageUpload value={dataItem[item.column]} onChange={(value)=>{
                                                    setDataItem({...dataItem,[item.column]:value})
                                                }} />
                                            </Form.Item>
                                        );
                                    case "datetime":
                                        return (
                                            <Form.Item label={item.label} name={item.column} key={index}>
                                                <DatePicker showTime disabled={!getInputEnable(item)} format='YYYY-MM-DD HH:mm:ss' value={moment(dataItem[item.column])} onChange={(value)=>{
                                                    setDataItem({...dataItem,[item.column]:value})
                                                }}/>
                                            </Form.Item>
                                        );
                                    case "json":
                                        tabs[item.label] = (
                                            <Form.Item name={item.column} key={index}>
                                                <JsonInput
                                                    value={dataItem[item.column]}
                                                    onChange={(value) => {
                                                        setDataItem({ ...dataItem, [item.column]: value });
                                                    }}
                                                />
                                            </Form.Item>
                                        );
                                        break;
                                    default:
                                        return (
                                            <Form.Item
                                                label={item.label}
                                                name={item.column}
                                                key={index}
                                            >
                                                <Input />
                                            </Form.Item>
                                        );
                                }
                                return <></>;
                            })}
                        {randerTab(tabs)}
                        {
                            formOperationItems.map(item => {
                                return <Button key={item.label} onClick={() => handleFormOperation(item, form.getFieldsValue())}>{item.label}</Button>
                            })
                        }
                    </Form>
                </Col>
            </Row>
        );
    };
    return renderForm()
}
export default memo(CommonForm)